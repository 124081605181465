<template>
  <div class="box advertising-card mt-3 is-flex">
    <img :src="advertisingData.image" />
    <div class="ml-4 is-flex is-align-items-center">
      <div>
        <h1 class="has-text-weight-bold is-size-7 text-ellipsis-title">
          {{ advertisingData.title }}
        </h1>
        <p class="is-size-7 text-ellipsis-text paragraph">
          {{ advertisingData.description }}
        </p>
        <button
          v-if="advertisingData.type === 'event'"
          class="button is-small is-fullwidth mt-4 is-primary-profile"
          @click="$router.push({ name: 'Events' })"
        >
          Ir a evento
        </button>
        <button
          v-else-if="advertisingData.type === 'announcement'"
          class="button is-small is-fullwidth mt-4 is-primary-profile"
          @click="$router.push({ name: 'Job' })"
        >
          Ir a convocatoria
        </button>
        <hr v-if="isActionButtonsOn" />
        <div
          v-if="isActionButtonsOn"
          class="is-flex is-justify-content-space-between mt-3"
        >
          <button
            class="button is-small is-primary-profile is-outlined"
            @click="deleteAdvertising(advertisingData.id)"
          >
            Eliminar
          </button>
          <button
            class="button is-small is-primary-profile"
            @click="
              $router.push({
                name: 'EditAdvertising',
                params: { id: advertisingData.id },
              })
            "
          >
            Editar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import advertisingService from "@/services/Advertising";

export default {
  name: "AdvertisingCard",
  props: {
    advertisingData: {
      required: true,
    },
    isActionButtonsOn: {
      default: false,
    },
  },
  methods: {
    deleteAdvertising(advertisingId) {
      this.$buefy.dialog.confirm({
        title: "Eliminar publicidad",
        message: "¿Estás seguro que deseas eliminar esta publicidad?",
        type: "is-primary-profile",
        cancelText: "Cancelar",
        confirmText: "Eliminar",
        size: "is-small",
        onConfirm: async () => {
          try {
            await advertisingService.deleteAdvertising(advertisingId);
            await this.$router.push({ name: "ViewAllAdvertising" });
            this.$buefy.toast.open({
              duration: 5000,
              message: "Publicidad eliminada exitosamente",
              position: "is-bottom",
              type: "is-success",
            });
          } catch (e) {
            this.$buefy.toast.open({
              duration: 5000,
              message: e.response.data.message,
              position: "is-bottom",
              type: "is-danger",
            });
          }
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.advertising-card {
  height: auto;
  margin: 0 auto;
  background-color: #ffffff;
  .button {
    border: 1.5px solid white;
    border-radius: 5px;
  }
  hr {
    height: 1px !important;
    margin: 5px 0px;
    background-color: #d5d5d5;
  }
  .image-container {
    width: 100%;
  }
  img {
    height: 150px;
  }
  .text-ellipsis-text {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .text-ellipsis-title {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .paragraph {
    min-height: 70px;
  }
}
</style>
