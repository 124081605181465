<template src="./Profile.html"></template>

<script>
import { mapGetters } from "vuex";
import { SwiperSlide } from "vue-awesome-swiper";
import recommendationsService from "@/services/Recommendations";
import certificateService from "@/services/Certificate";
import coursesService from "@/services/Courses";
import ArrobaMedellinHorizontalCardAcademic from "@/components/ArrobaMedellinHorizontalCardAcademic/ArrobaMedellinHorizontalCardAcademic";
import ArrobaMedellinHorizontalCard from "@/components/ArrobaMedellinHorizontalCard/ArrobaMedellinHorizontalCard";
import ArrobaMedellinVerticalCard from "@/components/ArrobaMedellinVerticalCard/ArrobaMedellinVerticalCard";
import ProfileInformationCard from "./ProfileInformationCard/ProfileInformationCard";
import swiperLargeOption from "@/utils/swiper-large-option";
import ProfileRecommendation from "@/components/ProfileRecommendation/ProfileRecommendation";
import Certificates from "@/components/Certificates/Certificates";
import Avatar from "@/components/Avatar/Avatar";
import ArrobaMedellinArrobitaNoData from "@/components/ArrobaMedellinArrobitaNoData/ArrobaMedellinArrobitaNoData";
import ArrobaMedellinArrobitaNoDataProfile from "@/components/ArrobaMedellinArrobitaNoDataProfile/ArrobaMedellinArrobitaNoDataProfile";
import ArrobaMedellinNoData from "../../components/ArrobaMedellinNoData/ArrobaMedellinNoData.vue";
import profileService from "@/services/Profile";
import historyService from "@/services/History";
import { districtInfo } from "@/utils/constants";
import { roleName } from "@/utils/constants";
import tutorial from "@/mixins/tutorial";
import advertisingService from "@/services/Advertising";
import AdvertisingCard from "@/components/AdvertisingCard/AdvertisingCard";
import AdvertisingCardVertical from "@/components/AdvertisingCardVertical/AdvertisingCardVertical";

export default {
  name: "profile",
  mixins: [tutorial],
  components: {
    SwiperSlide,
    ArrobaMedellinHorizontalCardAcademic,
    ArrobaMedellinHorizontalCard,
    ArrobaMedellinVerticalCard,
    ProfileInformationCard,
    ProfileRecommendation,
    Certificates,
    Avatar,
    ArrobaMedellinArrobitaNoData,
    ArrobaMedellinArrobitaNoDataProfile,
    ArrobaMedellinNoData,
    AdvertisingCard,
    AdvertisingCardVertical,
  },
  data() {
    return {
      userRole: null,
      typeCard: { button: "button--blue", progress: "is-info" },
      swiperSmallOption: swiperLargeOption,
      courseHistory: [],
      historyUser: [],
      certificatesData: [],
      noCourses: false,
      itemsToShow: Number(1),
      recommendation: [],
      roles: roleName,
      allAdvertising: [],
      recommendationCounter: null,
      ranking: null,
      gradeCounter: null,
    };
  },
  mounted() {
    this.getRecommendationTeacher();
    this.getAverageScoreStars();
    window.addEventListener("resize", this.reportWindowSize);
    this.reportWindowSize();
    this.reloadUser();
    this.getHistory();
    this.getAllAdvertising();
    this.userRole = this.user.role.name;
    if (this.userRole === roleName.STUDENT) {
      this.getRecommendation();
      this.getCertificates();
      this.getCourses();
    }
    this.viewTutorial();
  },
  methods: {
    async reloadUser() {
      try {
        const user = await profileService.getUserInfo();
        this.$store.dispatch("fetchUser", user);
      } catch (error) {
        console.warn("No sesion for User");
        this.$router.push("/login");
      }
    },
    async getHistory() {
      try {
        this.historyUser = await historyService.getHistoryByUser();
      } catch (error) {
        console.warn("No history for User");
      }
    },
    async getAllAdvertising() {
      try {
        this.allAdvertising = await advertisingService.getAllAdvertising();
      } catch (error) {
        console.warn("No history for User");
      }
    },
    sendRoute(route) {
      this.$router.push({ path: `${route}` });
    },
    async getRecommendation() {
      try {
        this.recommendation = await recommendationsService.getRecommendation();
      } catch (error) {
        console.warn("No recommendation for User");
      }
    },
    async getAverageScoreStars() {
      try {
        this.ranking = (
          await profileService.getAverageScoreStars(this.user.id)
        ).averageStars;
        this.gradeCounter = (
          await profileService.getAverageScoreStars(this.user.id)
        ).totalQualification;
      } catch (error) {
        console.warn("Error");
      }
    },
    async getCertificates() {
      try {
        this.certificatesData = await certificateService.getCertificate();
      } catch (error) {
        console.warn("No certificates for User");
      }
    },
    async getRecommendationTeacher() {
      try {
        this.recommendationCounter = (
          await profileService.getRecommendationTeacher(this.user.id)
        ).countRecommendation;
      } catch (error) {
        console.warn("No certificates for User");
      }
    },
    async setRecommendationTeacher() {
      try {
        await profileService.setRecommendationTeacher(this.user.id);
        await this.getRecommendationTeacher();
      } catch (error) {
        console.warn("No certificates for User");
      }
    },
    async getCourses() {
      try {
        this.courseHistory =
          await coursesService.getContinuosEducationCourses();
      } catch (error) {
        console.warn("No Courses for User");
      }
    },
    reportWindowSize() {
      if (window.innerWidth < 550) {
        this.itemsToShow = Number(1);
      } else if (window.innerWidth < 1224) {
        this.itemsToShow = Number(2);
      } else if (window.innerWidth < 1580 || window.innerWidth > 1580) {
        this.itemsToShow = Number(3);
      }
    },
    selectActivityImage(district) {
      switch (district) {
        case "Perfíl":
          return "https://storage.googleapis.com/arrobamedellin.appspot.com/distritos%2FBotero.png";
        case "Academico":
          return "https://storage.googleapis.com/arrobamedellin.appspot.com/distritos%2FColtejer.png";
        case "Comercio":
          return "https://storage.googleapis.com/arrobamedellin.appspot.com/distritos%2FPlaza%20libertad.png";
        case "Eventos":
          return "https://storage.googleapis.com/arrobamedellin.appspot.com/distritos%2FPlaza%20mayor.png";
        case "I+D+I":
          return "https://storage.googleapis.com/arrobamedellin.appspot.com/distritos%2FParque%20explora.png";
        case "Networking":
          return "https://storage.googleapis.com/arrobamedellin.appspot.com/distritos%2FRuta%20N.png";
        case "Bolsa de trabajo":
          return "https://storage.googleapis.com/arrobamedellin.appspot.com/distritos%2FPlanetario.png";
      }
    },
    imageCourse(image) {
      if (image) {
        if (image.substring(0, 4) === "http") {
          return image;
        }
        return process.env.VUE_APP_ARROBA + "/images/cursos/" + image;
      }
      return null;
    },
    viewTutorial() {
      this.dataTutorial("profile");
    },
    redirectTo(section) {
      this.$router.push({ name: "ComercialStore", params: { section } });
    },
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
    getDistrictInfo() {
      return (district) => districtInfo.find((item) => item.name == district);
    },
    roleValidationNoAccess() {
      return ["Invited", "Administrator", "AdministratorCall"].some(
        (a) => a === this.userRole
      );
    },
  },
};
</script>
<style src="./Profile.scss" lang="scss" scoped></style>
